<template>
  <div class="align-items-center justify-content-center g-0 py-4">
    <h1 class="title">Rất tiếc không tìm thấy trang mà bạn yêu cầu</h1>
  </div>
</template>

<script>

export default {
  name: '404'
}
</script>
