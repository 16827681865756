<template>
  <router-link :class="this.class" :to="{name: 'UserDetail', params: {id: item.id}}" v-for="item in people" :key="'people' + item.id" :title="item.username">
    {{ item.username }}
  </router-link>
</template>

<script>
export default {
  name: "UsersOfSong",
  props: {
    people: Array,
    class: String
  }
}
</script>
