<template>
  <div>
    <not-found v-if="tag === null"/>
    <div v-if="tag">
      <div class="sm:my-6 my-3 flex items-center justify-between border-b pb-3 relative">
        <div>
          <h2 class="text-xl font-semibold">Tag: {{ tag.name }}</h2>
        </div>
        <select class="w-auto px-2 float-right" v-model="sort">
          <option value="views:DESC">Lượt nghe</option>
          <option value="title:ASC">Tên bài</option>
          <option value="created_at:DESC">Mới nhất</option>
        </select>
      </div>

      <div class="mt-4" v-if="tag.users.length">
        <p class="font-semibold">Xây dựng tag:</p>
        <div class="flex items-center">
          <router-link :uk-tooltip="'title: ' + user.username" v-for="(user, index) in tag.users"
                        v-bind:class="{'-ml-2': index > 0 }"
                        :key="'user' + user.id" :to="{name: 'UserDetail', params: {id: user.id}}"
                        :title="user.username">
            <img
                :alt="user.username"
                :src="user && user.avatar ? user.avatar.url : '/assets/images/avatars/avatar-2.jpg'"
                class="w-10 h-10 rounded-full border-2 border-white dark:border-gray-900">
          </router-link>
        </div>
      </div>

      <div id="songsTag" class="space-y-2 mt-4" v-if="songs.data && songs.data.length">
        <div class="flex justify-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Tân nhạc ({{songs.paginatorInfo.total}})
          </h3>
          <div>
            <router-link v-if="tag.slug" :to="{name: 'TagListen', params: {name: tag.slug}, query: {type: 'bai-hat'}}" title="Nghe tất cả"><i class="uil uil-volume"></i></router-link>
          </div>
        </div>
        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4">
            <song-item
              v-for="song in songs.data"
              :song="song"
              prefix="bai-hat"
              :key="'song' + song.id"
            />
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="songs.paginatorInfo.lastPage > 1"
                v-model="songs.paginatorInfo.currentPage"
                :pages="songs.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadSongs"
            />
          </div>
        </div>
      </div>
      <div id="instrumentalsTag" class="space-y-2 mt-4" v-if="instrumentals.data && instrumentals.data.length">
        <div class="flex justify-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Nhạc không lời ({{instrumentals.paginatorInfo.total}})
          </h3>
          <div>
            <router-link v-if="tag.slug" :to="{name: 'TagListen', params: {name: tag.slug}, query: {type: 'khi-nhac'}}" title="Nghe tất cả"><i class="uil uil-volume"></i></router-link>
          </div>
        </div>
        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4">
            <song-item
              v-for="song in instrumentals.data"
              :song="song"
              prefix="khi-nhac"
              :key="'instrumental' + song.id"
            />
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="instrumentals.paginatorInfo.lastPage > 1"
                v-model="instrumentals.paginatorInfo.currentPage"
                :pages="instrumentals.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadInstrumentals"
            />
          </div>
        </div>
      </div>
      <div id="karaokesTag" class="space-y-2 mt-4" v-if="karaokes.data && karaokes.data.length">
        <div class="flex justify-between">
          <h3 class="text-xl font-semibold line-clamp-1">
            Thành viên hát ({{karaokes.paginatorInfo.total}})
          </h3>
          <div>
            <router-link v-if="tag.slug" :to="{name: 'TagListen', params: {name: tag.slug}, query: {type: 'thanh-vien-hat'}}" title="Nghe tất cả"><i class="uil uil-volume"></i></router-link>
          </div>
        </div>
        <div class="card p-3">
          <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4">
            <song-item
              v-for="song in karaokes.data"
              :song="song"
              prefix="thanh-vien-hat"
              :key="'karaoke' + song.id"
            />
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-center"
                v-if="karaokes.paginatorInfo.lastPage > 1"
                v-model="karaokes.paginatorInfo.currentPage"
                :pages="karaokes.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadKaraokes"
            />
          </div>
        </div>
      </div>

      <div class="mt-4">
        <comment object-type="tag" :comment-id="commentId" :object-id="tag.id" :slug="tag.slug" field="slug" type="String"/>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import {
  changePageTitle,
  logActivity,
  getSongFieldsByType,
} from "../../core/services/utils.service";
import SongItem from "../SongItem";
import NotFound from "../404";
import Comment from "../comment/Comment";

export default {
  name: "TagDetail",
  components: { SongItem, NotFound, Comment },
  data() {
    return {
      id: null,
      commentId: null,
      tag: {
        id: 0,
        name: "",
        slug: "",
        users: []
      },
      sort: "views:DESC",
      sortColumn: "views",
      sortDirection: "DESC",
      songs: {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          lastPage: 0
        }
      },
      instrumentals: {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          lastPage: 0
        }
      },
      karaokes: {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          lastPage: 0
        }
      },
    };
  },
  methods: {
    loadTag() {
      let query = `query($slug: String!) {
        tag(slug: $slug) {
          id
          name
          slug
          users {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      ApiService.graphql(query, {slug: this.tag.slug})
        .then(({ data }) => {
          if (data.data && data.data.tag) {
            this.tag = data.data.tag;
            this.id = this.tag.id;
            if (this.tag && this.tag.users) {
              const key = 'id';
              this.tag.users = [...new Map(this.tag.users.map(item => [item[key], item])).values()];
            }
            this.loadSongs();
            this.loadInstrumentals();
            this.loadKaraokes();
            changePageTitle("Tag: " + this.tag.name);
            logActivity("view", "tag", this.tag.id);
          } else {
            this.tag = null;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadSongs() {
      let songFields = getSongFieldsByType("song");

      let query = `query($tag: String!, $page: Int, $orderBy: [OrderByClause!]) {
        songsByTag(first: 10, page: $page, tag: $tag, orderBy: $orderBy) {
          data {
            id
            slug
            title
            subtitle
            views
            thumbnail {
              url
            }
            ${songFields}
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query, { tag: this.tag.name, page: this.songs.paginatorInfo.currentPage, orderBy: this.getOrderBy() })
        .then(({ data }) => {
          if (data.data && data.data.songsByTag) {
            this.songs = data.data.songsByTag;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadInstrumentals() {
      let songFields = getSongFieldsByType("instrumental");

      let query = `query($tag: String!, $page: Int, $orderBy: [OrderByClause!]) {
        instrumentalsByTag(first: 10, page: $page, tag: $tag, orderBy: $orderBy) {
          data {
            id
            slug
            title
            subtitle
            views
            thumbnail {
              url
            }
            ${songFields}
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query, { tag: this.tag.name, page: this.instrumentals.paginatorInfo.currentPage, orderBy: this.getOrderBy() })
        .then(({ data }) => {
          if (data.data && data.data.instrumentalsByTag) {
            this.instrumentals = data.data.instrumentalsByTag;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadKaraokes() {
      let songFields = getSongFieldsByType("karaoke");

      let query = `query($tag: String!, $page: Int, $orderBy: [OrderByClause!]) {
        karaokesByTag(first: 10, page: $page, tag: $tag, orderBy: $orderBy) {
          data {
            id
            slug
            title
            subtitle
            views
            thumbnail {
              url
            }
            ${songFields}
          }
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query, { tag: this.tag.name, page: this.karaokes.paginatorInfo.currentPage, orderBy: this.getOrderBy() })
        .then(({ data }) => {
          if (data.data && data.data.karaokesByTag) {
            this.karaokes = data.data.karaokesByTag;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    getOrderBy() {
      return [{column: this.sortColumn, order: this.sortDirection}];
    }
  },
  watch: {
    sort: {
      handler(sort) {
        let tmp = sort.split(":");
        this.sortColumn = tmp[0];
        this.sortDirection = tmp[1];
        this.loadTag();
      }
    },
  },
  mounted() {
    this.commentId = this.$route.query.comment_id;
    this.tag.slug = this.$route.params.name;
    this.loadTag();
  },
  beforeRouteUpdate(to, from, next) {
    this.tag.slug = this.$route.params.name;
    window.scrollTo(0, 0);
    next();
    this.loadTag();
  },
};
</script>
