<template>
  <div class="flex justify-content-between hover:bg-gray-100 rounded-md py-3 -mx-2 px-2">
    <div class="flex items-center space-x-4">
      <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <router-link v-if="song.id && prefix !== 'thanh-vien-hat'" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          <img :alt="song.title" :src="song.thumbnail ? song.thumbnail.url : song.artists && song.artists.data && song.artists.data[0] && song.artists.data[0].avatar ? song.artists.data[0].avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
        </router-link>
        <router-link v-if="song.id && prefix === 'thanh-vien-hat'" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          <img :alt="song.title" :src="song.thumbnail ? song.thumbnail.url : song.user && song.user.avatar ? song.user.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'" class="absolute w-full h-full inset-0 rounded-md object-cover">
        </router-link>
        <img v-if="!song.id" :alt="song.title" src="https://static.bcdcnt.net/assets/images/group/group-3.jpg" class="absolute w-full h-full inset-0 rounded-md object-cover">
      </div>
      <div class="flex-1">
        <router-link v-if="song.id" :title="song.title" class="text-lg font-semibold" :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: prefix}}">
          {{ song.title }}
          <span class="italic text-sm font-normal" v-if="song.subtitle">{{ song.subtitle }}</span>
          <span class="italic text-sm font-normal" v-if="song.id === currentId">(Đang nghe)</span>
        </router-link>
        <div v-if="!song.id" class="text-lg font-semibold">{{ song.title }} <span class="text-gray-500" v-if="song.subtitle">{{ song.subtitle }}</span></div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song" v-if="song.artists && song.artists.data">
          <PeopleOfSong :people="song.artists.data" type="artist"/>
        </div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song" v-if="!song.artists && song.sheet && song.sheet.composers && song.sheet.composers.data && song.sheet.composers.data.length">
          <PeopleOfSong :people="song.sheet.composers.data" type="composer"/>
        </div>
        <div class="text-sm text-gray-500 mt-0.5 people-in-song" v-if="song.users && song.users.data">
          <users-of-song :people="song.users.data"/>
        </div>
        <div class="text-sm text-gray-500 mt-1" :title="thousandFormat(song.views) + ' lượt nghe'">
          <i class="uil uil-headphones"></i> {{ numberFormat(song.views) }}
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <span uk-tooltip="title: Bản thu VOV chất lượng cao" v-if="song.file && song.file.is_hq == 1" style="background: rgb(113, 19, 19); border-radius:3px" class="mt-3 whitespace-nowrap px-1 text-white">vov-hq</span>
    </div>
  </div>
</template>

<script>
import PeopleOfSong from "./song/PeopleOfSong";
import UsersOfSong from "./song/UserOfSong";
import { numberFormat, thousandFormat } from "../core/services/utils.service";

export default {
  name: "SongItem",
  props: {
    prefix: String,
    currentId: String,
    song: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  },
  components: {
    UsersOfSong,
    PeopleOfSong
  },
  methods: {
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    }
  }
}
</script>
